export const validateCardExpirationDate = (value: string) => {
  const cardMonth = parseInt(value.slice(0, 2));
  const cardYear = parseInt("20" + value.slice(2));

  // Validate month range
  if (cardMonth < 1 || cardMonth > 12) {
    return false;
  }

  const todayMonth = new Date().getMonth() + 1; // because getMonth is zero indexed
  const todayYear = new Date().getFullYear();

  const isValid =
    todayYear < cardYear || (todayYear === cardYear && todayMonth < cardMonth);

  return isValid;
};

export default validateCardExpirationDate;
