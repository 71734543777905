import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import validateCardExpirationDate from "../../../../../../helpers/validate-card-expiry";
import { PHONE_NUMBER_REGEX } from "../../../../../lib/config";

interface OptionalValidation {
  allowedBins?: string[];
}

export const useCardFormik = (props: {
  onSubmit: (values: CardChannelProperties) => Promise<void>;
  optionalValidation?: OptionalValidation;
}) => {
  const { onSubmit, optionalValidation } = props;
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  } = useFormik<CardFormValues>({
    initialValues: (() => {
      return {
        cardNumber: "",
        cardExpiry: "",
        cardHolderFirstName: "",
        cardHolderLastName: "",
        cvn: "",
        cardHolderEmail: "",
        cardHolderPhoneNumber: ""
      };
    })(),
    onSubmit: async (values: CardFormValues) => {
      const [expiryMonth, expiryYear] = values.cardExpiry.split("/");
      const currentYearFirstTwo = new Date()
        .getFullYear()
        .toString()
        .slice(0, 2);
      const fullExpiryYear = `${currentYearFirstTwo}${expiryYear}`;

      await onSubmit({
        card_details: {
          card_number: values.cardNumber,
          expiry_month: expiryMonth,
          expiry_year: fullExpiryYear,
          cvn: values.cvn,
          cardholder_first_name: values.cardHolderFirstName,
          cardholder_last_name: values.cardHolderLastName,
          cardholder_email: values.cardHolderEmail,
          cardholder_phone_number: values.cardHolderPhoneNumber
        }
      });
    },
    validate: (values: CardFormValues) => {
      const errors: FormikErrors<CardFormValues> = {};
      const sanitizedCardExpiry = values.cardExpiry.replace(/\//g, "");

      if (
        values.cardExpiry &&
        !values.cardExpiry.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)
      ) {
        errors.cardExpiry = "Card expiry should be in MM/YY format";
      }

      if (
        sanitizedCardExpiry.length === 4 &&
        !validateCardExpirationDate(sanitizedCardExpiry)
      ) {
        errors.cardExpiry = "Card has passed the expiry date";
      }

      return errors;
    },
    validationSchema: yup.object().shape(
      {
        cardNumber: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .test({
            name: "allowed_bins",
            message: "Card number is not supported for this session",
            test: (value) => {
              if (optionalValidation?.allowedBins?.length) {
                return optionalValidation.allowedBins.some((bin) =>
                  value?.startsWith(bin)
                );
              }
              return true;
            }
          })
          .min(15, "Incomplete card number")
          .max(16, "Incomplete card number")
          .required("Card number is required"),
        cardExpiry: yup.string().required("Expiry date is required"),
        cvn: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(3, "Incomplete CVN")
          .max(4, "Incomplete CVN")
          .required("CVN is required"),
        cardHolderFirstName: yup
          .string()
          .required("Cannot be empty")
          .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace
        cardHolderLastName: yup
          .string()
          .required("Cannot be empty")
          .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace
        cardHolderEmail: yup
          .string()
          .email("Invalid email address")
          .required("Cannot be empty"),
        cardHolderPhoneNumber: yup
          .string()
          .matches(PHONE_NUMBER_REGEX, "Must be a valid phone number")
          .required("Cannot be empty")
      },
      [["cardHolderEmail", "cardHolderPhoneNumber"]]
    )
  });

  return {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  };
};

export type CardFormValues = {
  cardNumber: string;
  cardExpiry: string;
  cvn: string;
  cardHolderFirstName: string;
  cardHolderLastName: string;
  cardHolderEmail: string;
  cardHolderPhoneNumber: string;
};

export type CardChannelProperties = {
  card_details: {
    card_number: string;
    expiry_month: string;
    expiry_year: string;
    cvn: string;
    cardholder_first_name: string;
    cardholder_last_name: string;
    cardholder_email: string;
    cardholder_phone_number: string;
  };
};
