import * as yup from "yup";
import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import type { FormikErrors, FormikProps } from "formik";

import Button from "../../components/Button";
import CreditCardBillingAddressDetails from "../../components/CreditCardBillingAddressDetails";
import CreditCardInstallmentOptions, {
  InstallmentOption
} from "../../components/CreditCardInstallmentOptions";
import CreditCardForm from "../../components/CreditCardForm";
import PromotionsDialog, {
  PromoCodeInput,
  PromoList
} from "../../components/PromotionsDialog";
import { Tag, TagButton, TagIcon, TagLabel } from "../../components/Tag";
import SimulationBar from "../../components/SimulationBar";

import { PriceTag, X } from "../../assets/icons";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { useCreditCardPayment } from "../../contexts/CreditCardPaymentContext";
import SimulationProvider from "../../contexts/SimulationContext";

import {
  getCardFullMonthYear,
  isMonthValid,
  validateCardExpirationDate
} from "../../helpers/card-valid-thru";
import { ENABLE_SIMULATION } from "../../utils/constants";
import {
  ExternalAnalyticsEvent,
  ExternalAnalyticsProvider,
  InternalAnalyticsEvent,
  logExternalAnalyticsEvent,
  logInternalAnalyticsEvent
} from "../../utils/analytics";
import {
  CreditCardFormValues,
  CreditCardPaymentOnSubmit,
  CreditCardPromotion,
  CreditCardValidationErrors,
  TokenizationRequest
} from "../../types/credit-card";
import NexBanner from "../../components/NexBanner";
import { nexBannerClickEvent } from "../../utils/nex-events";
import CreditCardUserDetails from "../../components/CreditCardUserDetails";
import { formatToE164 } from "../../utils/mobile-number";
import { countries } from "../../helpers/countries";

export type ChargeOptionsHooks = {
  cardNumber: string;
  invoiceId: string;
  amount: number;
  currency: string;
  businessId: string;
  promoCode?: string;
};

export type CreditCardPaymentProps = {
  onSubmit: (creditCardData: CreditCardPaymentOnSubmit) => void;
  creditCardValidationErrors: CreditCardValidationErrors | null;
  isFetchingChargeOptions: boolean;
  allowFullPayment?: boolean;
};

const CreditCardPayment = (props: FormikProps<CreditCardFormValues>) => {
  const { t } = useTranslation("forms");

  // installment
  const [installment, setInstallment] = useState<InstallmentOption | null>(
    null
  );

  // promotion
  const [openPromoDialog, setOpenPromoDialog] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>("");

  const {
    onSetPromotion,
    paymentLink: {
      cards_settings,
      invoice: { channel_properties: channelProperties },
      invoice_settings: {
        should_show_billing_details_for_cards: shouldShowBillingDetailsForCards
      },
      geo_location: { country_code: countryCode }
    }
  } = usePaymentLink();

  const {
    invoiceId,
    chargeOptions,
    promotion,
    tokenData,
    onCreateToken,
    onSetCreditCardPromotion,
    onGetChargeOptions: handleGetChargeOptions
  } = useCreditCardPayment();

  /**
   * Memoize installment options based on installment configuration.
   * Returns a tuple of `installmentOptions` as the base installment
   * option values, and `dropdownInstallmentOptions` as the formatted
   * installment options for the dropdown.
   */
  const [installmentOptions, dropdownInstallmentOptions] = useMemo(() => {
    // retrieve all allowed term counts
    const allowedTerms =
      channelProperties?.cards?.installment_configuration?.allowed_terms || [];
    const allowedTermCounts = uniq(
      allowedTerms.reduce((agg, term) => {
        if (
          chargeOptions?.bin_data.bank_code &&
          term.issuer !== chargeOptions.bin_data.bank_code
        ) {
          return agg;
        }

        return [...agg, ...term.terms];
      }, [] as number[])
    );

    // Check if installment is preconfigured on invoice creation
    const installmentIsPreconfigured =
      channelProperties?.cards?.installment_configuration &&
      !isEmpty(allowedTerms);

    const availableInstallments = chargeOptions?.installments;

    const filteredInstallments = chargeOptions?.installments.filter(
      (option) => {
        return allowedTermCounts.includes(option.count);
      }
    );

    // If installment is preconfigured, filter to only show the preconfigured ones
    // Else show all the available installments
    const filteredInstallmentOptions = installmentIsPreconfigured
      ? filteredInstallments
      : availableInstallments;

    const sortedInstallmentOptions = sortBy(filteredInstallmentOptions, [
      "acquirer",
      "count"
    ]);

    // add full payment option if allowed
    const shouldAllowFullPayment =
      channelProperties?.cards?.installment_configuration
        ?.allow_full_payment !== false;
    if (shouldAllowFullPayment) {
      sortedInstallmentOptions.unshift({
        count: 0,
        interval: "month",
        minimum_amount: 0,
        acquirer: "",
        currency: "",
        estimated_amount_per_interval: 0,
        description: t("Pay in full"),
        installment_amount: 0
      });
    }

    const dropdownInstallmentOptions = sortedInstallmentOptions.map((s, i) => {
      return {
        label: s.code?.includes("pinelabs")
          ? `Pinelabs - ${s.description}`
          : s.description,
        value: i,
        count: s.count,
        interval: s.interval
      };
    });

    return [sortedInstallmentOptions, dropdownInstallmentOptions];
  }, [
    channelProperties?.cards?.installment_configuration,
    chargeOptions?.installments,
    chargeOptions?.bin_data.bank_code
  ]);

  const handleInstallmentChange = (installmentOption: InstallmentOption) => {
    setInstallment(installmentOption);
    const selectedInstallment = installmentOptions[installmentOption.value];
    if (selectedInstallment.count !== 0) {
      props.setFieldValue("installment", selectedInstallment);
    } else {
      props.setFieldValue("installment", null);
    }
  };

  const handlePromoChange = (promoCode: string) => {
    setPromoCode(promoCode);
  };

  const handleApplyPromoCode = (promotion: CreditCardPromotion) => {
    props.setFieldValue("promotion", promotion);

    onSetCreditCardPromotion(promotion as CreditCardPromotion);
    setOpenPromoDialog(false);

    onSetPromotion(promotion);
  };

  const handleCheckPromoCode = async () => {
    handleGetChargeOptions(
      props.values.cardNumber.slice(0, 6),
      get(tokenData, "id", null),
      promoCode
    );
  };

  const handleResetPromotion = () => {
    setPromoCode("");
    onSetCreditCardPromotion(null);

    onSetPromotion(null);
    props.setFieldValue("promotion", null);
  };

  /**
   * Handle token creation and subsequent get charge options for
   * SAFE_ACCEPTANCE flow.
   */
  useEffect(() => {
    if (
      cards_settings?.cards_payment_channel === "SAFE_ACCEPTANCE" &&
      props.isValid &&
      props.values.cvn.length >= 3
    ) {
      const { cardMonth, cardYear } = getCardFullMonthYear(
        props.values.validThru
      );

      const tokenPayload = {
        card_number: props.values.cardNumber,
        card_exp_month: cardMonth,
        card_exp_year: cardYear,
        card_cvn: props.values.cvn
      } as Partial<TokenizationRequest>;

      if (props.values.userDetailsRequired) {
        tokenPayload.card_holder_first_name = props.values.givenName;
        tokenPayload.card_holder_last_name = props.values.surname;
        tokenPayload.card_holder_email = props.values.email;
        tokenPayload.card_holder_phone_number = `+${props.values.dialCode}${props.values.mobileNumber}`;
      }

      onCreateToken(tokenPayload);
    }
  }, [cards_settings?.cards_payment_channel, props.isValid]);

  useEffect(() => {
    // reset promo if card number changes
    handleResetPromotion();
  }, [props.values.cardNumber]);

  useEffect(() => {
    // directly applied promo if only has one bin promo
    if (chargeOptions?.promotions.length === 1) {
      handleApplyPromoCode(chargeOptions.promotions[0]);
    }
  }, [chargeOptions?.promotions]);

  useEffect(() => {
    // billing details are require for US, UK / GB, CA cards
    const countryRequiredBillingDetails = ["US", "UK", "GB", "CA"];
    if (
      shouldShowBillingDetailsForCards &&
      countryRequiredBillingDetails.includes(
        chargeOptions?.bin_data.country_code as string
      )
    ) {
      props.setFieldValue("billingAddressRequired", true);
      props.setFieldValue("country", chargeOptions?.bin_data.country_code);
    }
  }, [chargeOptions?.bin_data.country_code]);

  useEffect(() => {
    if (!isEmpty(promotion)) {
      handleApplyPromoCode(promotion);
    }
  }, [promotion]);

  useEffect(() => {
    props.setFieldValue(
      "userDetailsRequired",
      shouldShowBillingDetailsForCards
    );
    props.setFieldValue(
      "dialCode",
      countries.find((c) => c.iso2 === countryCode)?.dial_code
    );
  }, [shouldShowBillingDetailsForCards, countryCode]);

  useEffect(() => {
    // reset installment selection if installment options change
    if (dropdownInstallmentOptions.length === 1) {
      handleInstallmentChange(dropdownInstallmentOptions[0]);
    } else {
      setInstallment(null);
      props.setFieldValue("installment", null);
    }

    // set error if no installment options are available
    // because card issuer is not defined in any allowed terms
    if (
      dropdownInstallmentOptions.length === 0 &&
      channelProperties?.cards?.installment_configuration &&
      chargeOptions?.bin_data.bank_code
    ) {
      props.setFieldError(
        "cardNumber",
        "This card is not accepted for this transaction. Please use a different card."
      );
    } else {
      props.setFieldError("cardNumber", undefined);
    }
  }, [
    dropdownInstallmentOptions,
    channelProperties?.cards?.installment_configuration,
    chargeOptions?.bin_data.bank_code
  ]);

  // If installment is preconfigured to be not allowed, then we don't show the installment selector UI
  const installmentNotAllowed =
    channelProperties?.cards?.installment_configuration?.allow_installment ===
    false;

  const installmentSelector = installmentNotAllowed ? null : (
    <CreditCardInstallmentOptions
      allowFullPayment={
        channelProperties?.cards?.installment_configuration
          ?.allow_full_payment !== false
      }
      disabled={props.isSubmitting || dropdownInstallmentOptions.length < 2}
      installment={installment as InstallmentOption}
      installmentOptions={dropdownInstallmentOptions}
      handleChange={handleInstallmentChange}
      error={props.touched.installment ? props.errors.installment : undefined}
    />
  );

  const CreditCardPaymentContent = (
    <div className="px-4 pt-2 pb-6">
      <form onSubmit={props.handleSubmit} className="flex flex-col space-y-2">
        <CreditCardForm
          handleGetChargeOptions={handleGetChargeOptions}
          {...props}
        />

        {shouldShowBillingDetailsForCards ? (
          <CreditCardUserDetails countryCode={countryCode} {...props} />
        ) : null}

        {props.values.billingAddressRequired ? (
          <CreditCardBillingAddressDetails {...props} />
        ) : null}

        {installmentSelector}

        {(chargeOptions?.active_promotions_count as number) > 0 ? (
          <div className="py-1">
            {promotion ? (
              <div className="flex items-center gap-x-2">
                <span className="font-medium">Applied Promo Code</span>
                <Tag>
                  <TagIcon icon={PriceTag} className="ms-2" />
                  <TagLabel>
                    {promotion.promo_code || promotion.reference_id}
                  </TagLabel>
                  <TagButton
                    disabled={props.isSubmitting}
                    className="ms-2"
                    onClick={handleResetPromotion}
                    aria-label="close"
                  >
                    <TagIcon icon={X} />
                  </TagButton>
                </Tag>
              </div>
            ) : (
              <button
                type="button"
                onClick={() => setOpenPromoDialog(true)}
                className="font-medium"
              >
                See Available Promos Here
              </button>
            )}
          </div>
        ) : null}

        <div className="pt-4 flex justify-center">
          <Button
            variant="brand-secondary"
            type="submit"
            disabled={
              props.isSubmitting || props.status !== "idle" || !props.isValid
            }
            className="w-48"
            data-testid="pay-now"
          >
            {t("Pay Now")}
          </Button>
        </div>

        <div className="flex justify-center">
          <div style={{ maxWidth: 360 }}>
            <NexBanner
              bannerPlacementKey="placement_pre"
              onClick={() => nexBannerClickEvent("pre-payment-page", invoiceId)}
              className="pt-4"
            />
          </div>
        </div>
      </form>

      <PromotionsDialog
        open={openPromoDialog}
        title="Select or Enter Promo Code"
        content={
          <div className="mx-4">
            <PromoCodeInput
              value={promoCode}
              onChange={handlePromoChange}
              onPromoSubmit={handleCheckPromoCode}
              error={chargeOptions?.promotions.length === 0}
            />
            <PromoList
              promotions={chargeOptions?.promotions as CreditCardPromotion[]}
              onClickApplyPromo={(promotion) => handleApplyPromoCode(promotion)}
            />
          </div>
        }
        onClose={() => setOpenPromoDialog(false)}
      />
    </div>
  );

  if (ENABLE_SIMULATION) {
    return (
      <SimulationProvider>
        <SimulationBar />
        {CreditCardPaymentContent}
      </SimulationProvider>
    );
  }

  return CreditCardPaymentContent;
};

const EnhancedCreditCardPayment = withFormik<
  CreditCardPaymentProps,
  CreditCardFormValues
>({
  mapPropsToValues: () => ({
    cardNumber: "",
    validThru: "",
    cvn: "",
    userDetailsRequired: false,
    billingAddressRequired: false,
    installment: null,
    promotion: null,
    givenName: "",
    surname: "",
    email: "",
    dialCode: "",
    mobileNumber: "",
    country: "",
    provinceState: "",
    city: "",
    streetLine1: "",
    postalCode: ""
  }),
  mapPropsToStatus: (props) => {
    if (props.isFetchingChargeOptions) {
      return "fetchingChargeOptions";
    }
    if (!isEmpty(props.creditCardValidationErrors)) {
      return "validationError";
    }

    return "idle";
  },
  validate: (values, props) => {
    const errors: FormikErrors<CreditCardFormValues> = {};

    if (values.validThru.length === 4 && !isMonthValid(values.validThru)) {
      errors.validThru = "Valid thru month is not valid";
    }

    if (
      values.validThru.length === 4 &&
      !validateCardExpirationDate(values.validThru)
    ) {
      errors.validThru = "Card has past expiry date";
    }

    if (props.creditCardValidationErrors?.cardNumber) {
      errors.cardNumber = props.creditCardValidationErrors.cardNumber;
    }

    if (values.installment === null && !props.allowFullPayment) {
      errors.installment = "Please select an installment option";
    }

    return errors;
  },
  validationSchema: yup.object({
    cardNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(15, "Incomplete card number")
      .max(19, "Incomplete card number")
      .required("{{field}} is required"),
    validThru: yup
      .string()
      .length(4, "{{field}} is required")
      .required("{{field}} is required"),
    cvn: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(3, "Incomplete CVN")
      .max(4, "Incomplete CVN")
      .required("{{field}} is required"),
    email: yup
      .string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Must be a valid email")
      .when("userDetailsRequired", {
        is: true,
        then: (schema) => schema.required("{{field}} is required"),
        otherwise: (schema) => schema.optional()
      }),
    givenName: yup
      .string()
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Invalid name")
      .when("userDetailsRequired", {
        is: true,
        then: (schema) => schema.required("{{field}} is required"),
        otherwise: (schema) => schema.optional()
      })
      .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace,
    surname: yup
      .string()
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Invalid name")
      .when("userDetailsRequired", {
        is: true,
        then: (schema) => schema.required("{{field}} is required"),
        otherwise: (schema) => schema.optional()
      })
      .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace,
    dialCode: yup
      .string()
      .when("userDetailsRequired", {
        is: true,
        then: (schema) => schema.required("{{field}} is required"),
        otherwise: (schema) => schema.optional()
      })
      .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace,
    mobileNumber: yup
      .string()
      .matches(/^\d+$/, "Invalid mobile number")
      .when("userDetailsRequired", {
        is: true,
        then: (schema) => schema.required("{{field}} is required"),
        otherwise: (schema) => schema.optional()
      })
      .matches(/.*\S.*/, "Cannot be empty"), // Detect if it only contains whitespace,
    country: yup.string().when("billingAddressRequired", {
      is: true,
      then: (schema) => schema.required("{{field}} is required"),
      otherwise: (schema) => schema.optional()
    }),
    provinceState: yup.string().when("billingAddressRequired", {
      is: true,
      then: (schema) => schema.required("{{field}} is required"),
      otherwise: (schema) => schema.optional()
    }),
    city: yup.string().when("billingAddressRequired", {
      is: true,
      then: (schema) => schema.required("{{field}} is required"),
      otherwise: (schema) => schema.optional()
    }),
    streetLine1: yup.string().when("billingAddressRequired", {
      is: true,
      then: (schema) => schema.required("{{field}} is required"),
      otherwise: (schema) => schema.optional()
    }),
    postalCode: yup.string().when("billingAddressRequired", {
      is: true,
      then: (schema) => schema.required("{{field}} is required"),
      otherwise: (schema) => schema.optional()
    })
  }),
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    setSubmitting(true);

    const { cardMonth, cardYear } = getCardFullMonthYear(values.validThru);
    const creditCardData = {
      card_number: values.cardNumber,
      card_exp_month: cardMonth,
      card_exp_year: cardYear,
      card_cvn: values.cvn,
      installment: values.installment,
      promotion: values.promotion,
      billing_details_required: values.billingAddressRequired,
      set_submitting: setSubmitting
    } as CreditCardPaymentOnSubmit;

    if (values.userDetailsRequired) {
      creditCardData.card_holder_first_name = values.givenName;
      creditCardData.card_holder_last_name = values.surname;
      creditCardData.card_holder_email = values.email;
      creditCardData.card_holder_phone_number = formatToE164(
        values.mobileNumber as string,
        values.dialCode
      );
    }

    if (values.billingAddressRequired) {
      creditCardData.billing_details = {
        given_names: values.givenName as string,
        surname: values.surname as string,
        address: {
          country: values.country as string,
          province_state: values.provinceState as string,
          city: values.city as string,
          street_line1: values.streetLine1 as string,
          postal_code: values.postalCode as string
        },
        email: values.email as string
      };
    }

    // send external analytics event on form submission
    logInternalAnalyticsEvent({
      event: InternalAnalyticsEvent.SUBMIT_PAYMENT
    });

    // send external submit analytics event on form submission
    logExternalAnalyticsEvent({
      event_name: ExternalAnalyticsEvent.SUBMIT,
      target: [
        ExternalAnalyticsProvider.FACEBOOK,
        ExternalAnalyticsProvider.GOOGLE
      ]
    });

    onSubmit(creditCardData);
  },
  displayName: "CreditCardPayment",
  enableReinitialize: true
})(CreditCardPayment);

export default memo(EnhancedCreditCardPayment);
